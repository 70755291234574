import React from 'react'

const Footer = () => {
    return (

        <footer className="bg-black py-3 text-center text-white">
            Copyright © 2023  Yog Treatment;   All rights reserved
        </footer>
    )
}

export default Footer